
import { defineComponent, onMounted, ref } from 'vue'
import Actions from '@/components/Actions.vue'
import Tag from '@/components/Tag.vue'
import TimeChecker from '@/components/TimeChecker.vue'
import { read, remove } from '@/guides/endpoints/notification'

export default defineComponent({
  name: 'Task',
  components: {
    Actions,
    Tag,
    TimeChecker,
  },
  props: {
    data: Object,
  } as any, // eslint-disable-line

  setup(props) {
    const root = ref()

    if (!props.data.read) {
      const onEnterViewport: IntersectionObserverCallback = (
        entries,
        observer
      ) => {
        entries.forEach(entry => {
          observer.unobserve(entry.target)
        })

        read(props.data.id)

        observer.disconnect()
      }

      onMounted(() => {
        const observer = new IntersectionObserver(onEnterViewport)
        observer.observe(root.value)
      })
    }

    const onLink = () => {
      window.location.href = props.data.link.url
    }

    const onDelete = () => {
      root.value.parentNode.removeChild(root.value)
      remove(props.data.id)
    }

    const actions = [
      {
        key: 'delete',
        value: 'Supprimer',
      },
    ]

    if (props?.data?.link?.url) {
      actions.unshift({
        key: 'read',
        value: 'Lire',
      })
    }

    return {
      root,
      onLink,
      onDelete,
      actions,
    }
  },
})
