
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Actions',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup(props, ctx) {
    const isOpen = ref(false)
    const onClick = () => {
      isOpen.value = true
    }

    const clickOutside = () => {
      isOpen.value = false
    }

    const fireAction = (key: string) => {
      ctx.emit(key)
      isOpen.value = false
    }

    return {
      isOpen,
      onClick,
      clickOutside,
      fireAction,
    }
  },
})
