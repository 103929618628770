<template>
  <div class="actions" v-click-outside="clickOutside">
    <div class="actions-inner" @click.prevent="onClick">
      <div class="actions__dots">
        <SvgSprite symbol="icons-dots" size="24" />
      </div>
    </div>
    <div class="actions__items" v-if="isOpen">
      <div
        v-for="item in data.actions"
        :key="item.key"
        class="actions__item"
        @click="fireAction(item.key)"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Actions',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup(props, ctx) {
    const isOpen = ref(false)
    const onClick = () => {
      isOpen.value = true
    }

    const clickOutside = () => {
      isOpen.value = false
    }

    const fireAction = (key: string) => {
      ctx.emit(key)
      isOpen.value = false
    }

    return {
      isOpen,
      onClick,
      clickOutside,
      fireAction,
    }
  },
})
</script>

<style lang="scss" scoped>
.actions-inner {
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.actions__dots {
  @include center-xy;

  width: 24px;
  height: 24px;
}

.actions__items {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  background-color: $c-white;
  border: 1px solid $c-gray-lightest;
}

.actions__item {
  padding: $spacing / 2 $spacing / 2;
  font-size: 13px;
  line-height: 1em;
  white-space: nowrap;
  cursor: pointer;

  & + & {
    background-color: $c-gray-lightest;
  }
}
</style>
