<template>
  <div class="notifications" v-if="chromeData">
    <Hero
      title="Centre de notifications"
      :picture="chromeData?.headers.notifications"
    >
      <Filter
        v-model:value="filter"
        :options="['Rappels', 'Urgences', 'Actualités']"
      />
    </Hero>

    <div v-if="notifications.length > 0" class="list wrapper">
      <Notification v-for="item in notifications" :data="item" :key="item.id" />
    </div>

    <div class="empty wrapper" v-else-if="!loading">
      <h4 class="mb-xxs">Rien à signaler !</h4>
      <p>
        Il semblerait que tu n'aies aucune notification pour l'instant. C'est
        une excellente nouvelle : cela signifie que tu es à jour.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import Hero from '@/components/Hero.vue'
import Filter from '@/components/Filter.vue'
import Notification from '@/components/Notification.vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/notification'

export default defineComponent({
  name: 'Notifications',
  components: {
    Hero,
    Notification,
    Filter,
  },
  setup() {
    const chromeData = ref()
    const loading = ref(true)
    const notifications = ref([])
    const filter = ref(null)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const fetch = () => {
      const filters = ['reminder', 'emergency', 'news']
      let type = undefined

      if (filter.value !== null) {
        type = filters[filter.value || 0]
      }

      list(type)
        .then(r => {
          notifications.value = r.data.notifications
        })
        .then(() => {
          loading.value = false
        })
    }

    watch(() => filter.value, fetch)

    onMounted(() => {
      fetch()
    })

    return {
      chromeData,
      notifications,
      filter,
      loading,
    }
  },
})
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  max-width: 1200px;
  gap: 15px;

  @include mq($from: s, $until: m) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: xxxl) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.empty {
  text-align: center;

  h4 {
    color: $rock-blue;
  }

  p {
    max-width: 370px;
    font-size: 12px;
    line-height: 16px;
    margin: 0 auto;
  }
}
</style>
