<template>
  <div class="notification" ref="root">
    <div class="notification-inner">
      <div class="notification__header">
        <div class="notification__header__icon">
          <SvgSprite :symbol="`icons-${data.type}`" size="20" />
        </div>
        <div class="notification__header__category">
          <template v-if="data.type === 'reminder'">Rappel</template>
          <template v-if="data.type === 'emergency'">Urgence</template>
          <template v-if="data.type === 'news'">Actualités</template>
        </div>
        <Tag
          v-if="!data.read"
          class="notification__header__tag"
          :data="{ label: 'New' }"
        />
      </div>
      <h4 class="notification__title" v-html="data.title"></h4>
      <div class="notification__text">
        {{ data.text }}
      </div>
    </div>
    <Actions
      class="notification__actions"
      @read="onLink"
      @delete="onDelete"
      :data="{
        actions,
      }"
    />
    <div class="notification__time">
      <TimeChecker
        class="notification__time__label"
        :data="{ date: data.created_at * 1000 }"
        type="notification"
      />
    </div>

    <a
      v-if="data?.link?.url"
      class="notification__link"
      :href="data.link.url"
    ></a>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import Actions from '@/components/Actions.vue'
import Tag from '@/components/Tag.vue'
import TimeChecker from '@/components/TimeChecker.vue'
import { read, remove } from '@/guides/endpoints/notification'

export default defineComponent({
  name: 'Task',
  components: {
    Actions,
    Tag,
    TimeChecker,
  },
  props: {
    data: Object,
  } as any, // eslint-disable-line

  setup(props) {
    const root = ref()

    if (!props.data.read) {
      const onEnterViewport: IntersectionObserverCallback = (
        entries,
        observer
      ) => {
        entries.forEach(entry => {
          observer.unobserve(entry.target)
        })

        read(props.data.id)

        observer.disconnect()
      }

      onMounted(() => {
        const observer = new IntersectionObserver(onEnterViewport)
        observer.observe(root.value)
      })
    }

    const onLink = () => {
      window.location.href = props.data.link.url
    }

    const onDelete = () => {
      root.value.parentNode.removeChild(root.value)
      remove(props.data.id)
    }

    const actions = [
      {
        key: 'delete',
        value: 'Supprimer',
      },
    ]

    if (props?.data?.link?.url) {
      actions.unshift({
        key: 'read',
        value: 'Lire',
      })
    }

    return {
      root,
      onLink,
      onDelete,
      actions,
    }
  },
})
</script>

<style lang="scss" scoped>
.notification {
  position: relative;
  border: 1px solid $athens-gray;
  padding: $spacing * 0.75 $spacing $spacing * 1.5 $spacing;
  background-color: $c-white;
}

.notification__header {
  display: flex;
  align-items: center;
}

.notification__header__icon {
  position: relative;
  top: -1px;
  font-size: 0;

  svg {
    width: 25px;
    height: 25px;
  }
}

.notification__header__category {
  margin-left: 3px;
  color: $regal-blue;
  font-size: 1.2rem;
  line-height: 1em;
  font-weight: 700;
}

.notification__header__tag {
  margin: 0 0 0 $spacing / 2;
}

.notification__title,
.notification__text {
  max-width: 230px;
}

.notification__title {
  margin-top: $spacing;
}

.notification__text {
  @include owl;

  margin-top: $spacing / 4;
  font-size: 1.2rem;
  line-height: 1.4em;
}

.notification__actions {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.notification__time {
  position: absolute;
  top: 17px;
  right: 10px;
  display: flex;
  align-items: center;
}

.notification__time__label {
  color: $hippie-blue;
  font-size: 10px;
}

.notification__link {
  @include get-all-space;
  display: block;
  z-index: 10;
}
</style>

<style lang="scss">
.notification__title {
  strong {
    color: $tangerine;
  }
}
</style>
