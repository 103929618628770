
import { defineComponent, onMounted, ref, watch } from 'vue'
import Hero from '@/components/Hero.vue'
import Filter from '@/components/Filter.vue'
import Notification from '@/components/Notification.vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list } from '@/guides/endpoints/notification'

export default defineComponent({
  name: 'Notifications',
  components: {
    Hero,
    Notification,
    Filter,
  },
  setup() {
    const chromeData = ref()
    const loading = ref(true)
    const notifications = ref([])
    const filter = ref(null)

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    const fetch = () => {
      const filters = ['reminder', 'emergency', 'news']
      let type = undefined

      if (filter.value !== null) {
        type = filters[filter.value || 0]
      }

      list(type)
        .then(r => {
          notifications.value = r.data.notifications
        })
        .then(() => {
          loading.value = false
        })
    }

    watch(() => filter.value, fetch)

    onMounted(() => {
      fetch()
    })

    return {
      chromeData,
      notifications,
      filter,
      loading,
    }
  },
})
